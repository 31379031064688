<template>
    <div v-if="tokenChecked">
        <div class="flex-center container message" v-if="setNewPasswordToWpPlugin">
            <div>
                <p>Пароль удачно изменен. Вы можете авторизоваться через wordpress плагин. </p>
                <p>По любым вопросам обращайтесь в службу поддержки </p>
                <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
            </div>
        </div>
        <div class="flex-center container" v-else-if="tokenIsValid">
            <form class="restore-password-form" @submit.prevent="" novalidate>
                <AuthFormErrorComponent :error="errors.main"></AuthFormErrorComponent>
                <h2 class="input-title">Восстановить пароль</h2>
                <p class="input-title">Укажите новый пароль</p>
                <BaseInput
                        type="password"
                        isPassword="true"
                        :error="errors.passwordError"
                        v-model="formData.password">
                </BaseInput>
                <p class="input-title">Введите повторно пароль</p>
                <BaseInput
                        type="password"
                        isPassword="true"
                        :error="errors.confirmPasswordError"
                        v-model="formData.confirmPassword">
                </BaseInput>
                <div class="flex-end">
                    <BaseButton class='btn-primary'
                                type="submit"
                                :processing="loading"
                                @click="loginWithApi">Подтвердить</BaseButton>
                </div>
            </form>
        </div>
        <div class="flex-column message container" v-else>
            <p>Ссылка сброса пароля не действительна.</p><p>По любым вопросам обращайтесь в службу поддержки </p><a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
        </div>
    </div>
    <BaseLoader v-else
                color="#e8703b"
                :height="80"
                :width="80">
    </BaseLoader>

</template>

<script>
    import { mapActions } from 'vuex';
    import AuthFormErrorComponent from "./AuthFormErrorComponent";
    export default {
        name: "SetNewPasswordComponent",
        mounted(){
            this.CHECK_PASWORD_RESTORE_TOKEN({
                params: {user_type: 1},
                token: this.$route.params.token
            })
            .then(()=>{
                this.tokenChecked = true;
                this.tokenIsValid = true;
            })
            .catch(()=>{
                this.tokenChecked = true;
                this.tokenIsValid = false;
            })
        },
        data(){
            return {
                loading: false,
                tokenChecked: false,
                tokenIsValid: false,
                setNewPasswordToWpPlugin: false,
                formData: {
                    confirmPassword: '',
                    password: ''
                },
                errors: {
                    main: '',
                    passwordError: '',
                    confirmPasswordError: '',
                },
                errorText : {
                    emptyPlace: 'Все поля должны быть заполнены',
                    passwordDontMatch: 'Пароли не совпадают',
                    passwordToSmall: 'Пароль не может быть короче 8 символов',
                    tokenInvalid: 'Ошибка восстановления пароля. Время жизни ссылки сброса пароля истекло',
                }
            }
        },
        computed: {
            links(){
                return this.$store.state.static.routePaths
            },
        },
        methods: {
            ...mapActions(['CHECK_PASWORD_RESTORE_TOKEN','SET_NEW_PASSWORD','GET_USER_INFO','GET_USER_PUBLISHER_INFO']),
            loginWithApi(){
                this.clearErrors();

                if (!this.formData.confirmPassword.length || !this.formData.password.length) {
                    this.errors.main = this.errorText.emptyPlace;
                    !this.formData.confirmPassword.length ? this.errors.confirmPasswordError = true  : this.errors.confirmPasswordError = '';
                    !this.formData.password.length ? this.errors.passwordError = true : this.errors.passwordError = '';
                    return
                }

                if (this.formData.password.length < 8) {
                    this.errors.passwordError = true;
                    this.errors.main = this.errorText.passwordToSmall;
                    return;
                }

                if (this.formData.password !== this.formData.confirmPassword) {
                    this.errors.main = this.errorText.passwordDontMatch;
                    return;
                }

                this.loading = true;//Покажем лоадер на кнопке
                this.CHECK_PASWORD_RESTORE_TOKEN({
                    params: {user_type: 1},
                    token: this.$route.params.token
                })
                    .then(()=>{
                        this.SET_NEW_PASSWORD({
                            token: this.$route.params.token,
                            password:this.formData.password
                        })
                            .then((response)=> {
                                if (response && response.access_token) {
                                    this.loading = false;
                                    this.clearErrors();
                                    if (response.user_type === 1) {
                                        this.GET_USER_INFO()
                                            .then(() => {
                                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                                            })
                                            .catch(() => {
                                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                                            })
                                    } else {
                                        this.GET_USER_PUBLISHER_INFO()
                                            .then(() => {
                                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                                            })
                                            .catch(() => {
                                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                                            })
                                    }
                                } else {
                                    this.setNewPasswordToWpPlugin = true;
                                }

                            })
                            .catch(()=> {
                                this.loading = false;
                                this.errors.main = this.errorText.tokenInvalid;
                            })
                    })
                    .catch(()=>{
                        this.loading = false;
                        this.errors.main = this.errorText.tokenInvalid;
                    })

            },

            clearErrors(){
                this.errors = {
                    main: '',
                    confirmPasswordError: '',
                    passwordError: '',
                }
            },
        },
        components: {
            AuthFormErrorComponent
        },
    }
</script>

<style lang="scss" scoped>
    .restore-password-form {
        height: auto;
        position: relative;
    }
    .container {
        min-height: 100vh;
    }
    .message {
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        min-height: 100vh;
        text-align: center;
        @media (max-width: 768px) {
            font-size: 14px;
            text-align: center;
        }
        a {
            color: #e8703b;
            text-decoration: underline;
        }
    }
</style>
