<template>
    <div class="flex-center container">
        <div class="check-yor-email flex-center flex-column" v-if="needConfirmFromEmail">
            <p>На ваш e-mail была отправлена ссылка с подтверждением. Ссылка будет действительна в течении 24 часов</p>
            <p>По любым вопросам обращайтесь в службу поддержки</p>
            <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
        </div>
        <form class="restore-password-form"
              @submit.prevent=""
              v-else
              novalidate>
            <AuthFormErrorComponent :error="errors.main"></AuthFormErrorComponent>
            <header class="flex-center flex-between">
                <h2 class="title">Восстановить пароль</h2>
                <div class="select-user-type flex auth-header">
                    <BaseButton class="btn-default"
                                v-for="(userType, index) in userTypes"
                                :key="index"
                                :class="{'btn-filled': activeTab === userType}"
                                @click="setUserType(userType, index)">
                        {{tabsNames[userType]}}
                    </BaseButton>
                </div>
            </header>
            <p class="input-title">Ваш e-mail</p>
            <BaseInput
                    type="email"
                    :error="errors.emailError"
                    v-model="formData.email">
            </BaseInput>
            <div class="flex-between">
                <BaseButton class='btn btn-primary empty' @click="cancel">Назад</BaseButton>
                <BaseButton class='btn btn-primary'
                            type="submit"
                            :processing="loading"
                            @click="sendRequestToNewPassword">Подтвердить</BaseButton>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import AuthFormErrorComponent from "./AuthFormErrorComponent";
    export default {
        name: "PasswordRestoreComponent",
        data(){
            return {
                activeTab: null,
                userType: 1,
                userTypes: ['blogger', 'webmaster'],
                tabsNames:{
                    blogger: 'Блогер',
                    webmaster: 'Вебмастер',
                },
                loading: false,
                needConfirmFromEmail: false,
                formData: {
                    email: ''
                },
                errors: {
                    main: '',
                    emailError: '',
                },
                errorText : {
                    notValidEmail: 'Введите корректный e-mail',
                    emailNotFound: 'Пользователя с таким email не найдено',
                }
            }
        },
        mounted(){
            this.formData.email = this.$route.params.initEmail || '';
            this.activeTab = this.$route.params.activeTab || 'blogger';
        },
        methods: {
            ...mapActions(['SEND_REQUEST_FOR_RESTORE']),
            sendRequestToNewPassword(){
                this.clearErrors();

                if (!this.validateEmail(this.formData.email)) {
                    this.errors.emailError = true;
                    this.errors.main = this.errorText.notValidEmail;
                    return;
                }

                this.loading = true;//Покажем лоадер на кнопке

                this.SEND_REQUEST_FOR_RESTORE({
                    email:this.formData.email,
                    user_type: this.userType
                })
                .then(()=> {
                    this.loading = false;
                    this.clearErrors();
                    this.needConfirmFromEmail = true ;
                })
                .catch(()=> {
                    this.loading = false;
                    this.errors.main = this.errorText.emailNotFound
                })
            },
            validateEmail(text){
                return text.match(/@/g) && text.match(/@/g).length === 1
            },
            clearErrors(){
                this.errors = {
                    main: '',
                    emailError: '',
                }
            },
            setUserType(name, index){
                this.activeTab = name;
                this.userType = index + 1;
            },
            cancel() {
                this.$router.push(this.$store.state.static.routePaths.login);
            },
        },
        components:{
            AuthFormErrorComponent
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        justify-content: center;
    }
    .restore-password-form, .check-yor-email {
        height: 360px;
        width: 100%;
        max-width: 620px;
        border: solid 1px #d0d0d0;
        padding: 40px 35px;
        @media (max-width: 768px) {
            padding: 25px 15px ;
            /*height: auto;*/
        }
    }
    .check-yor-email{
        color: #424242;
        font-size: 20px;
        font-weight: bold;
        justify-content: center;
        p {
            margin-top: 0;
            margin-bottom: 20px;
        }
        @media (max-width: 768px) {
            font-size: 14px;
            p {
                margin-bottom: 15px;
            }
        }
        a {
            color: #e8703b;
            text-decoration: underline;
        }
    }
    .auth-header {
        margin-bottom: 36px;
        @media (max-width: 768px) {
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
</style>
