import { render, staticRenderFns } from "./PasswordRestoreComponent.vue?vue&type=template&id=919aba50&scoped=true&"
import script from "./PasswordRestoreComponent.vue?vue&type=script&lang=js&"
export * from "./PasswordRestoreComponent.vue?vue&type=script&lang=js&"
import style0 from "./PasswordRestoreComponent.vue?vue&type=style&index=0&id=919aba50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "919aba50",
  null
  
)

export default component.exports