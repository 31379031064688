<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "PasswordRestoreScreen",
    }
</script>

<style lang="scss" scoped>
    ::v-deep .restore-password-form {
        position: relative;
        height: 340px;
        width: 100%;
        max-width: 460px;
        border: solid 1px #d0d0d0;
        padding: 40px 35px;
        @media (max-width: 768px) {
            padding: 20px 10px 30px;
            height: auto;
        }
        h2 {
            color: #424242;
            font-size: 24px;
            margin-bottom: 35px;
        }
        .btn {
            margin-top: 25px;
        }
        .input-title {
            color:#424242;
            font-weight: bold;
        }
        .links-block {
            margin: 5px 0 15px;
            a {
                color: #e8703b;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }

</style>
